import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import SelectInput from '../SelectInput';
import TextInput from '../text-input';

import '../Input.scss';
import './SelectPhoneInput.scss';

interface CustomProps {
  data: any[];
  optionLabel?: string;
  optionValue?: string;
  onChange: (value: any) => void;
  error?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  type?: 'text' | 'number';
  gap?: 'sm' | 'md' | 'lg';
  defaultSelected?: string;
  defaultCode?: string;
  defaultEntered?: string | number;
  zIndex?: number;
  width?: {
    code?: number;
    select?: number;
    text?: number;
  };
  enableSelectSearch?: boolean;
  disableSelected?: boolean;
  disableNumberScrolls?: boolean;
  outerClass?: string;
  innerClass?: string;
  codeInputClass?: string;
}

interface CustomState {
  value: {
    code?: string | number;
    selected?: string | number;
    entered?: string | number;
  };
}

class SelectPhoneInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    placeholder: '',
    error: '',
    name: '',
    label: '',
    optionLabel: 'label',
    optionValue: 'value',
    type: 'text',
    gap: 'md',
    defaultSelected: '',
    defaultCode: '',
    defaultEntered: '',
    zIndex: 1,
    width: { code: 15, select: 31, text: 55 },
    enableSelectSearch: false,
    disableSelected: false,
    disableNumberScrolls: false,
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: {
        code: props.defaultCode,
        selected: props.defaultSelected,
        entered: props.defaultEntered,
      },
    };
  }

  handleChange(
    field: 'code' | 'selected' | 'entered',
    fieldValue: string | number
  ) {
    if (field === 'code') {
      this.setState((prevState) => {
        const value = {
          code: fieldValue,
          selected: prevState.value.selected,
          entered: prevState.value.entered,
        };
        this.props.onChange(value);
        return { value };
      });
    }
    if (field === 'selected') {
      this.setState(
        (prevState: CustomState): CustomState => {
          const value = {
            code: prevState.value.code,
            selected: fieldValue,
            entered: prevState.value.entered,
          };
          this.props.onChange(value);
          return {
            ...prevState,
            value,
          };
        }
      );
    }
    if (field === 'entered') {
      this.setState((prevState) => {
        const value = {
          code: prevState.value.code,
          selected: prevState.value.selected,
          entered: fieldValue,
        };
        this.props.onChange(value);
        return { value };
      });
    }
  }

  render() {
    const {
      data,
      optionLabel,
      optionValue,
      error,
      name,
      label,
      placeholder,

      gap,
      type,
      zIndex,
      width,
      enableSelectSearch,
      outerClass,
      innerClass,
      codeInputClass,
    } = this.props;
    const selectProps = { data, optionLabel, optionValue };
    const textInputProps = { placeholder, type };
    const inputIdCandidate = name || label || placeholder;
    const inputId = inputIdCandidate;

    return (
      // <div className={`Input Input--gap-${gap} PhoneInput phone-select`}>
      <div className={`custom-phone-input`}>
        {label && (
          // <label className="Input__label" htmlFor={`${inputId}-country-code`}>
          <label
            className="block pb-1 text-sm font-medium"
            htmlFor={`${inputId}-country-code`}
          >
            {label}
          </label>
        )}
        <div className={`flex ${outerClass ? outerClass : ''}`}>
          <TextInput
            {...textInputProps}
            default={`+${this.props.defaultCode}`}
            onChange={(val: string | number) => this.handleChange('code', val)}
            // stickyStyles={{
            //   position: 'absolute',
            //   width: `${(width && width.code) || 100}%`,
            //   zIndex: `${zIndex}`,
            // }}
            id={`${inputId}-country-code`}
            disabled
            className={`form-control w-[60px] px-2 text-center !bg-white ltr:mr-[5px] rtl:ml-[5px] ${
              codeInputClass ? codeInputClass : ''
            }`}
            type="text"
          />
          <div
            className={`border border-[#DBDBDB] rounded flex flex-grow ${
              innerClass ? innerClass : ''
            }`}
          >
            <div className="flex items-center min-w-[62px]">
              <div className="ltr:border-r rtl:border-l w-full">
                <SelectInput
                  {...selectProps}
                  data={[
                    { label: '50', value: '50' },
                    { label: '52', value: '52' },
                    { label: '54', value: '54' },
                    { label: '55', value: '55' },
                    { label: '56', value: '56' },
                    { label: '58', value: '58' },
                  ]}
                  default={this.props.defaultSelected}
                  onChange={(val: string | number) =>
                    this.handleChange('selected', val)
                  }
                  // stickyStyles={{
                  //   position: 'absolute',
                  //   width: `${(width && width.select) || 100}%`,
                  //   zIndex: `${zIndex}`,
                  //   paddingLeft: `${((width && width.code) || 0.5) - 0.5}%`,
                  // }}
                  isSearchable={enableSelectSearch}
                  disabled={this.props.disableSelected}
                  id={`${inputId}-starting-code`}
                />
              </div>
            </div>
            <div className="w-full">
              <TextInput
                {...textInputProps}
                onChange={(val: string | number) =>
                  this.handleChange('entered', val)
                }
                // stickyStyles={{
                //   position: 'relative',
                //   paddingLeft: `${((width && width.select) || 0.5) - 0.5}%`,
                //   width: `${(width && width.text) || 100}%`,
                // }}
                disabled={this.props.disableSelected}
                disableNumberScrolls={this.props.disableNumberScrolls}
                default={this.props.defaultEntered}
                className="border-none rounded-none disable-number-scrolls"
                onInput={(e) => {
                  const target = e.target as HTMLInputElement;
                  if (target.value.length > 7) {
                    target.value = target.value.slice(0, 7);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {error && <span className="Input__error py-1">{error}</span>}
      </div>
    );
  }
}

// SelectTextInput.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   optionLabel: PropTypes.string,
//   optionValue: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   error: PropTypes.string,
//   name: PropTypes.string,
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
//   type: PropTypes.oneOf(['text', 'number']),
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   defaultSelected: PropTypes.string,
//   defaultEntered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   zIndex: PropTypes.number,
//   width: PropTypes.shape({
//     select: PropTypes.number.isRequired,
//     text: PropTypes.number.isRequired,
//   }),
//   enableSelectSearch: PropTypes.bool,
//   disableSelected: PropTypes.bool,
//   disableNumberScrolls: PropTypes.bool,
// };

// SelectTextInput.defaultProps = {
//   placeholder: '',
//   error: '',
//   name: '',
//   label: '',
//   optionLabel: 'label',
//   optionValue: 'value',
//   type: 'text',
//   gap: 'md',
//   defaultSelected: '',
//   defaultEntered: '',
//   zIndex: 1,
//   width: { select: 20, text: 80 },
//   enableSelectSearch: false,
//   disableSelected: false,
//   disableNumberScrolls: false,
// };

export default SelectPhoneInput;

import React, { Component } from 'react';
import Select from 'react-select';
import api from '../../../api';
import { CrudListRequestModel } from '../../../api/models';
import { Dict } from '../../../models/interfaces';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '10',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
  }),
};

interface CustomProps {
  default?: any[];
  api?: string;
  data?: any[];
  placeholder?: string;
  label?: string;
  id?: string;
  optionLabel?: string;
  optionValue?: string;
  onChange: (value: any[], selected: any[]) => void;
  gap?: 'sm' | 'md' | 'lg';
  error?: string;
  name?: string;
  defaultIfNotFound?: any;
  disabled?: boolean;
  stickyStyles?: {};
  isSearchable?: boolean;
  isRtl?: boolean;
  queryParams?: Dict;
  className?: string;
  filterFunc?: (value: any) => void;
}

interface CustomState {
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  selected: any[];
  options: any[];
}

class MultiSelectInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: [],
    gap: 'md',
    placeholder: 'Select...',
    error: '',
    name: '',
    label: '',
    data: [],
    disabled: false,
    optionLabel: 'label',
    optionValue: 'value',
    stickyStyles: {},
    isSearchable: true,
    isRtl: false,
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isLoading: false,
      isRtl: props.isRtl,
      isSearchable: props.isSearchable,
      selected: props.default || [],
      options: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    if (this.props.api) {
      const request: CrudListRequestModel = {
        resource: this.props.api,
        pagination: {},
        sort: {},
        queryParams: this.props.queryParams,
      };
      this.toggleLoading();
      let { data } = await api.crud.getList(request);
      this.toggleLoading();

      if (this.props?.filterFunc) {
        data = data.filter(this.props.filterFunc);
      }

      this.setState(() => ({
        options: data,
      }), () => {
        this.updateSelectedOptions();
      });
    } else {
      this.setState(() => ({
        options: this.props.data || [],
      }), () => {
        this.updateSelectedOptions();
      });
    }
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.default !== this.props.default) {
      this.setState({ selected: this.props.default || [] }, () => {
        this.updateSelectedOptions();
      });
    }
  }

  toggleLoading = () =>
    this.setState((state) => ({ isLoading: !state.isLoading }));

  toggleRtl = () => this.setState((state) => ({ isRtl: !state.isRtl }));

  toggleSearchable = () =>
    this.setState((state) => ({ isSearchable: !state.isSearchable }));

  updateSelectedOptions() {
    const { options, selected } = this.state;
    const selectedOptions = options.filter((option: any) =>
      selected.includes(option[this.props.optionValue || ''])
    );
    this.setState({ selected: selectedOptions });
  }

  handleChange(selected: any) {
    this.setState({
      selected,
    });
    const selectedValues = selected.map(
      (item: any) => item[this.props.optionValue || '']
    );
    this.props.onChange(selectedValues, selected);
  }

  render() {
    const {
      gap,
      name,
      label,
      placeholder,
      stickyStyles,
      error,
      id,
      className,
    } = this.props;
    const { isSearchable, isLoading, isRtl, selected, options } = this.state;
    const inputId = id || name || label || placeholder;

    return (
      <div style={stickyStyles}>
        {label && (
          <label htmlFor={inputId} className="block pb-1 text-dark text-sm font-medium">
            {label}
          </label>
        )}
        <Select
          isMulti
          styles={customStyles}
          className={`basic-multi-select ${className}`}
          classNamePrefix="select"
          isLoading={isLoading}
          isRtl={isRtl}
          isSearchable={isSearchable}
          placeholder={placeholder}
          getOptionLabel={(option) => option[this.props.optionLabel || '']}
          getOptionValue={(option) => option[this.props.optionValue || '']}
          options={options}
          onChange={this.handleChange}
          value={selected}
          isDisabled={this.props.disabled}
          id={inputId}
        />
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

export default MultiSelectInput;
